export const personalInfo = {
  id: 1,
  name: "Mohitul Islam",
  imgUrl: "/images/mohit-without-edit.jpeg",
  alt: "mohitul_islam",
  designation: "Frontend Engineer",
  info: "I possess three years of professional experience in the software industry, with one year of dedicated employment in this field. During my tenure, I have actively contributed to numerous projects, some of which have been successfully deployed on the web. I am currently employed at a software company, where I continue to grow and contribute to the industry.",
};

export const brandName = [
  {
    id: 1,
    name: "mongoDB",
  },
  {
    id: 2,
    name: "Express",
  },
  {
    id: 3,
    name: "React",
  },
  {
    id: 4,
    name: "Node JS",
  },
  {
    id: 6,
    name: "Graphql",
  },
  {
    id: 7,
    name: "Redux",
  },
];

export const skills = [
  {
    id: 5,
    skillName: "JavaScript",
    percentage: "90%",
    code: 9,
  },
  {
    id: 1,
    skillName: "React",
    percentage: "90%",
    code: 9,
  },
  {
    id: 2,
    skillName: "Next Js",
    percentage: "90%",
    code: 9,
  },
  {
    id: 3,
    skillName: "Node",
    percentage: "80%",
    code: 8,
  },
  {
    id: 4,
    skillName: "TypeScript",
    percentage: "80%",
    code: 8,
  },
];

// About me

export const aboutMeInfo = {
  id: 1,
  exp: "3 Year’s Experience",
  designation: "on Frontend Engineering",
  aboutMe:
    " I possess three years of professional experiences in the software industry, with one year of dedicated employment in this field. During my tenure, I have actively contributed to numerous projects, some of which have been successfully deployed on the web. I am currently employed at a software company, where I continue to grow and contribute to the industry.",
};

// Services

export const services = [
  {
    id: 1,
    title: "Web Apps Development",
    desc: "Creating Websites and Apps through UX/UI Design",
    icon: (
      <>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          class='icon icon-tabler icon-tabler-source-code'
          width='90'
          height='90'
          viewBox='0 0 24 24'
          stroke-width='2'
          stroke='#fff'
          fill='none'
          stroke-linecap='round'
          stroke-linejoin='round'
        >
          <path
            stroke='none'
            d='M0 0h24v24H0z'
            fill='none'
          />
          <path d='M14.5 4h2.5a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-10a3 3 0 0 1 -3 -3v-5' />
          <path d='M6 5l-2 2l2 2' />
          <path d='M10 9l2 -2l-2 -2' />
        </svg>
      </>
    ),
  },
  {
    id: 3,
    title: "Landing Page For Products",
    desc: "Landing page for any products through UX/UI Design",
    icon: (
      <>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          class='icon icon-tabler icon-tabler-app-window'
          width='90'
          height='90'
          viewBox='0 0 24 24'
          stroke-width='2'
          stroke='#fff'
          fill='none'
          stroke-linecap='round'
          stroke-linejoin='round'
        >
          <path
            stroke='none'
            d='M0 0h24v24H0z'
            fill='none'
          />
          <path d='M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z' />
          <path d='M6 8h.01' />
          <path d='M9 8h.01' />
        </svg>
      </>
    ),
  },
  {
    id: 3,
    title: "Typescript Web Apps",
    desc: "Developing Web apps with latest technologies",
    icon: (
      <>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          class='icon icon-tabler icon-tabler-code'
          width='90'
          height='90'
          viewBox='0 0 24 24'
          stroke-width='2'
          stroke='#fff'
          fill='none'
          stroke-linecap='round'
          stroke-linejoin='round'
        >
          <path
            stroke='none'
            d='M0 0h24v24H0z'
            fill='none'
          />
          <path d='M7 8l-4 4l4 4' />
          <path d='M17 8l4 4l-4 4' />
          <path d='M14 4l-4 16' />
        </svg>
      </>
    ),
  },
  {
    id: 4,
    title: "React Project Specific Packages",
    desc: "Creating react packages for any projects as per requirments",
    icon: (
      <>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          class='icon icon-tabler icon-tabler-brand-codesandbox'
          width='90'
          height='90'
          viewBox='0 0 24 24'
          stroke-width='2'
          stroke='#fff'
          fill='none'
          stroke-linecap='round'
          stroke-linejoin='round'
        >
          <path
            stroke='none'
            d='M0 0h24v24H0z'
            fill='none'
          />
          <path d='M20 7.5v9l-4 2.25l-4 2.25l-4 -2.25l-4 -2.25v-9l4 -2.25l4 -2.25l4 2.25z' />
          <path d='M12 12l4 -2.25l4 -2.25' />
          <path d='M12 12l0 9' />
          <path d='M12 12l-4 -2.25l-4 -2.25' />
          <path d='M20 12l-4 2v4.75' />
          <path d='M4 12l4 2l0 4.75' />
          <path d='M8 5.25l4 2.25l4 -2.25' />
        </svg>
      </>
    ),
  },
];

// Milestones

export const milestones = [
  {
    id: 1,
    number: "20+",
    text: "Projects are running developing for productions.",
  },
  {
    id: 2,
    number: "100%",
    text: "UI/UX based design for all projects structures",
  },
  {
    id: 3,
    number: "15+",
    text: "Live projects and their supports as long as need.",
  },
  {
    id: 4,
    number: "50+",
    text: "Make todo lists for traveling and make happend.",
  },
];
